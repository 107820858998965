<template>
    <!--手机端-->
    <el-container v-if="$_isMobile()">
        <el-header width="100%" class="head-flex">

            <div class="div1" @click="menudrawer = true"><span><i class="el-icon-menu"></i></span></div>
            <div class="div2">
                <span>客户管理系统</span>
            </div>
            <div class="div3">
                <el-menu 
                mode="horizontal" 
                background-color="#001529"
                text-color="#fff"
                active-text-color="#fff"
                :router="true">
                    
                    <el-submenu index="1">
                        <template slot="title">
                            <span  v-if="null != userInfo.username">{{userInfo.username | ellipsis}}</span>
                            <span  v-else>{{userInfo.phone | ellipsis}}</span>
                        </template>
                        <span class="el-menu-item2">
                            <el-menu-item :index="'/updatePassword'">修改密码</el-menu-item>
                            <el-menu-item  @click="logout">退出</el-menu-item>
                        </span>
                    </el-submenu>
                </el-menu>
                <!-- <el-button type="info" @click="logout" icon="el-icon-switch-button" size="mini">退出</el-button> -->
            </div>
        </el-header>
        <el-drawer
        class="el-drawer-menu"
        size="50%"
        :visible.sync="menudrawer"
        :direction="menudirection"
        :close="menuClosed">
            
            <el-menu
                class="el-menu-vertical-demo"
                background-color="#001529"
                text-color="#fff"
                active-text-color="#fff"
                :unique-opened="true"
                :router="true"
                :default-active="this.$route.path">


                <!-- 一级菜单 :index动态绑定ID 保持唯一 它只接受字符串所以加‘’ -->
                <template v-for="item in menulist">
                    <el-menu-item :index="item.path" :key="item.id" v-if="!item.children">
                            <i :class="[item.meta.icon]"></i>
                            <span slot="title">{{item.meta.title}}</span>
                    </el-menu-item>
                
                    <el-submenu :index="item.path" :key="item.id" v-else>
                        <template slot="title">
                            <i :class="[item.meta.icon]"></i>
                            <span>{{item.meta.title}}</span>
                        </template>
                        
                        <el-menu-item :index="subItem.path" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState(subItem.path)">
                            <template slot="title">
                                <i :class="[subItem.meta.icon]"></i>
                                <span>{{subItem.meta.title}}</span>
                            </template>
                        </el-menu-item>
                    </el-submenu> 
                </template>

            </el-menu>
        </el-drawer>
        <el-container>
            <el-main>
                <!--路由占位符-->
                <router-view></router-view>
            </el-main>
        </el-container>
        
    </el-container>
    <!--pc端-->
    <el-container class="home-container" v-else>
        
        <el-header width="100%">
            <div>
                <span>信息流管理后台</span>
            </div>
            <div>
            <el-menu
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
                background-color="#001529"
                text-color="#fff"
                active-text-color="#fff" 
                :unique-opened="true"
                :collapse="isCollapse"
                :collapse-transition="false"
                :router="true"
                :default-active="this.$route.path">
                <template v-for="item in menulist">
                        <el-menu-item :index="item.path" :key="item.id" v-if="!item.children">
                                <!-- <i :class="[item.meta.icon]"></i> -->
                                <span slot="title">{{item.meta.title}}</span>
                        </el-menu-item>
                    
                        <el-submenu :index="item.path" :key="item.id" v-else>
                            <template slot="title">
                                <!-- <i :class="[item.meta.icon]"></i> -->
                                <span>{{item.meta.title}}</span>
                            </template>
                            
                            <el-menu-item :index="subItem.path" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState(subItem.path)">
                                <template slot="title">
                                    <!-- <i :class="[subItem.meta.icon]"></i> -->
                                    <span>{{subItem.meta.title}}</span>
                                </template>
                            </el-menu-item>
                        </el-submenu> 
                    </template>
            </el-menu>
            </div>
            <div>
                <el-menu 
                class="el-menu-demo2" 
                mode="horizontal" 
                background-color="#001529"
                text-color="#fff"
                active-text-color="#fff"
                :router="true">
                    
                    <el-submenu index="1">
                        <template slot="title">
                            <span v-if="null != userInfo.username">欢迎您：{{userInfo.username}}</span>
                            <span v-else>欢迎您：{{userInfo.phone}}</span>
                        </template>
                        <span class="el-menu-item2">
                            <el-menu-item :index="'/updatePassword'">修改密码</el-menu-item>
                            <el-menu-item  @click="logout">退出</el-menu-item>
                        </span>
                    </el-submenu>
                </el-menu>
                <!-- <el-button type="info" @click="logout" icon="el-icon-switch-button" size="mini">退出</el-button> -->
            </div>
        </el-header>
        <el-container>
            <!-- 
                    :unique-opened="true"展开一个菜单 另一个就关闭
                    :router="true"开启路由模式 
                    :collapse="isCollapse" 动态绑定是否伸缩
                    $route.path
            -->
            <!-- 一级菜单 :index动态绑定ID 保持唯一 它只接受字符串所以加‘’ -->
            <!--
            <el-aside :width="isCollapse?'64px':'200px'">
                <div class="toggle-button" @click="toggleCollapse">|||</div>
                
                <el-menu
                    class="el-menu-vertical-demo"
                    background-color="#001529"
                    text-color="#fff"
                    active-text-color="#fff"
                    :unique-opened="true"
                    :collapse="isCollapse"
                    :collapse-transition="false"
                    :router="true"
                    :default-active="this.$route.path">


                    
                    <template v-for="item in menulist">
                        <el-menu-item :index="item.path" :key="item.id" v-if="!item.children">
                                <i :class="[item.meta.icon]"></i>
                                <span slot="title">{{item.meta.title}}</span>
                        </el-menu-item>
                    
                        <el-submenu :index="item.path" :key="item.id" v-else>
                            <template slot="title">
                                <i :class="[item.meta.icon]"></i>
                                <span>{{item.meta.title}}</span>
                            </template>
                            
                            <el-menu-item :index="subItem.path" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState(subItem.path)">
                                <template slot="title">
                                    <i :class="[subItem.meta.icon]"></i>
                                    <span>{{subItem.meta.title}}</span>
                                </template>
                            </el-menu-item>
                        </el-submenu> 
                    </template>

                </el-menu>
            </el-aside>
            -->
            <el-main>
                <!--路由占位符-->
                <router-view></router-view>
            </el-main>
        </el-container>

    </el-container>
</template>

<script>
export default {
    filters: {
        //超过5位显示...
        ellipsis: function(value) {
        if (!value) return "";
        if (value.length > 5) {
            return value.slice(0, 5) + "...";
        }
        return value;
        }
    }, 
    data(){
        return{
            menulist:[],
            isCollapse:false,
            activePath:'',
            userInfo:{},
            uid:'',

            //移动端menu
            menudrawer: false,
            menudirection: 'ltr',

        }
    },
    created(){
        this.uid = window.sessionStorage.getItem('uid')
        this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
        this.menulist = JSON.parse(window.sessionStorage.getItem('menulist'))
        console.log("Home页created，从缓存中获取");
        console.log(this.menulist);
        //this.getMenuList()
        this.activePath = window.sessionStorage.getItem('activePath')
        //this.$router.push
    },
    mounted(){
        
    },
    methods:{
        async logout(){
            console.log("logout--------");
            
            const {data:res} = await this.$http.post("sys/logout");
            console.log(res);
            if(res.code !== 200) return this.$message.error(res.message);
            window.sessionStorage.clear();
            this.$router.push('/');
        },
        //获取菜单
        async getMenuList(){
            console.log('获取菜单');
            console.log(window.sessionStorage.getItem('token'));
            const token = window.sessionStorage.getItem('token');
            console.log('token===='+token);
            const {data:res} = await this.$http.get('sys/permission/getUserPermissionByToken?token='+token);
            console.log(res);
            if(res.code !== 200) return this.$message.error(res.message);
        
            this.menulist = res.result.menu;
            console.log(this.menulist);
            // console.log("========res.result.auth=======");
            // console.log(
            //     JSON.stringify(res.result.auth)
            // );

            window.sessionStorage.setItem('menu',JSON.stringify(res.result.menu));
            window.sessionStorage.setItem('auth',JSON.stringify(res.result.auth));

            // if(JSON.stringify(res.result.menu).indexOf("WelcomeAdmin")>=0){
            //     console.log("走了if");
            //     this.$router.push('/Welcome');
            // }else{
            //     console.log("走了else");
            //     this.$router.push('/dashboard');
            // }
            
            
        },
        
        //切换菜单折叠与展开
        toggleCollapse(){
            this.isCollapse = !this.isCollapse
        },
        //保存连接的激活状态    直接用 :default-active="this.$route.path" 就解决了
        saveNavState(activePath){
            window.sessionStorage.setItem('activePath',activePath);
            this.activePath = activePath;
        },

        menuClosed(){
            this.drawer=false
        }

    }
}
</script>

<style lang="less" scoped>

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
// .el-menu-demo2:not(.el-menu--collapse) {
//     min-width: 150px;
// }

.el-menu.el-menu--horizontal{
    border-bottom: solid 0px #e6e6e6;
}


.home-container{
    height: 100%;
}
.el-header{
    background-color: #001529;//358F6C
    display: flex;
    justify-content: space-between;// flex-start
    padding-left: 0;
    padding-right: 0;
    align-items: center; //按钮上下剧中
    color:#fff;
    font-size: 20px;
    > div{
        display: flex;
        align-items: center; //上下剧中
        > span{
            text-align: center;
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}
.el-aside{
    background-color: #001529;//358F6C
    /deep/ .el-menu{
        border-right: 0;
        
        .el-menu-item.is-active{
            border-left: 4px solid #fff;
            background-color: #000 !important;//20684C
        }
        .el-menu-item,.el-submenu{
            i {
                color: #fff;
            }
        }
        .el-submenu__title i{
            color: #fff;
        }
        
    }
}
.el-main{
    background-color: #eaedf1;
}
.iconfont{
    margin-right: 10px;
}
.toggle-button{
    background-color: #000;//20684C
    font-size: 10px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.2em;//文字间距
    cursor: pointer;
}

.el-menu-demo2 /deep/ .el-submenu{
    min-width: 200px;
}
.el-menu-demo2 /deep/  .el-submenu__title i {
        color: #fff;
}

// .el-menu-demo2 {
//     /deep/ .el-menu-item {
//     min-width: 150px;
//     width: 150px;
//     }
// } 
// /deep/ .el-menu{
//     min-width: 150px;
// }
/deep/ .el-drawer{
    background-color: #001529;
}
/deep/ .el-drawer__body{
 
    overflow-y: scroll;
}


.head-flex{
    display: flex;
    //flex-flow: wrap;
    //justify-content:space-between ;
    .div1{
        //border: #fff 1px solid;
        width: 6rem;
        font-size: 24px;
        Justify-content: center;
        .el-icon-menu{
            Justify-content: center;
        }
    }
    .div2{
        //border: #fff 1px solid;
        width: 10rem;
        font-size: .96rem;
        Justify-content: center;
    }
    .div3{
        //border: #fff 1px solid;
        width: 8rem;
    }
}
.nickname{
    display: -webkit-box;/*作为弹性伸缩盒子模型显示*/
    -webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
    overflow: hidden; /*超出的文本隐藏*/
    text-overflow: ellipsis; /* 溢出用省略号*/
    -webkit-box-orient: vertical;/*伸缩盒子的子元素排列：从上到下*/
}
</style>